$(document).ready(function() {
  "use strict";

  /*++++++++++++++++++++++++++++++++++++++++
  ++++++++ Seite Aktiv +++++++++++++
  +++++++++++++++++++++++++++++++++++++++++
  */
  var loc = window.location;
  var first_path = loc.pathname;
  var n = first_path.lastIndexOf("/") + 1;
  var seite = first_path.substr(n).replace('.php', '');
  switch (seite) {
    case 'startseite':
      $("#nav_home").addClass('active');
      break;
    case 'body-line-premiumfitness':
      $("#nav_angebote").addClass('active');
      $("#sup_premium").addClass('active');
      break;
    case 'for-women':
      $("#nav_angebote").addClass('active');
      $("#sup_premium").addClass('active');
      break;
    case 'zirkeltraining':
      $("#nav_angebote").addClass('active');
      $("#sup_premium").addClass('active');
      break;
    case 'kurse':
      $("#nav_angebote").addClass('active');
      $("#sup_premium").addClass('active');
      break;
    case 'mach-dich-fit':
      $("#nav_angebote").addClass('active');
      $("#sup_mdf").addClass('active');
      break;
    case 'das-studio':
      $("#nav_angebote").addClass('active');
      $("#sup_mdf").addClass('active');
      break;
    case 'physioline':
      $("#nav_angebote").addClass('active');
      $("#sub_physioline").addClass('active');
      break;
    case 'rehabilitationssportverein':
      $("#nav_angebote").addClass('active');
      $("#sub_reha").addClass('active');
      break;
    case 'kostenloses-probetraining':
      $("#btn_training").addClass('active');
      break;
    case 'kontakt':
      $("#nav_kontakt").addClass('active');
      $("#sub_kontakt").addClass('active');
      break;
    case 'anfahrt':
      $("#nav_anfahrt").addClass('active');
      break;
    case 'datenschutz':
      $("#nav_datenschutz").addClass('active');
      $("#sub_datenschutz").addClass('active');
      break;
    case 'impressum':
      $("#nav_datenschutz").addClass('active');
      $("#sub_impressum").addClass('active');
      break;
    case 'aqualine':
      $("#nav_angebote").addClass('active');
      $("#sub_aqua").addClass('active');
      break;
    default:

      break;

  } //switch

});
