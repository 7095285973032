import jQuery from "jquery";
window.$ = window.jQuery = jQuery;
import bootstrap from 'bootstrap';
import swal from 'sweetalert';
import { WOW } from 'wowjs';
window.WOW = WOW;



/**
+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
 * ArtPictureDesign JS Class
 * @package Projekt Website
 * Copyright 2019, Jens Wiecker
 * License: Commercial - goto https://art-picturedesign.de
 * https://art-picturedesign.de/webdesign
 *
 +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
 */

//parallax


jQuery(document).ready(function() {

  //srollTop
  $(window).scroll(function() {
    if ($(this).scrollTop() > 100) {
      $('.scrollup').fadeIn();
      //$('.bg-dark').addClass('bg-dark-scroll');
      $(".navbar-brand").addClass("navbar-brand-show");

    } else {
      $('.scrollup').fadeOut();
      //$('.bg-dark').removeClass('bg-dark-scroll');
      $(".navbar-brand").removeClass("navbar-brand-show");
    }
  });

  $('.scrollup').click(function() {
    $("html, body").animate({
      scrollTop: 0
    }, 1000);
    return false;
  });

}); //document


$(document).ready(function() {
  "use strict";
  //IMIT WOW
  new WOW().init();
  //SITE SUBMENU SCROLL
  $(function() {
    $(".submenu a[href*='#']").stop().click(function() {
      if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
        var APD_HASH = this.hash;
        var APD_ZIEL = $(this.hash);
        if (APD_ZIEL.length) {
          var APD_ABSTAND_TOP = APD_ZIEL.offset().top;
          $('html,body').animate({
              scrollTop: APD_ABSTAND_TOP
            }, 1000,
            function() {
              window.location.hash = APD_HASH;
            });
        }
      }
    });
  });

}); //document
