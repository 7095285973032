/*+++++++++++++++++++++++++++
++++++++ FORMULARE ++++++++++
+++++++++++++++++++++++++++++
*/
$(document).ready(function() {
  "use strict";
  //Message Handle
  function error_message(msg) {
    var x = document.getElementById("snackbar_error");
    $("#snackbar_error").html(msg);
    x.className = "show";
    setTimeout(function() {
      x.className = x.className.replace("show", "");
    }, 4500);
  }

  function success_message(msg) {
    var x = document.getElementById("snackbar-success");
    $("#snackbar-success").text(msg);
    x.className = "show";
    setTimeout(function() {
      x.className = x.className.replace("show", "");
    }, 3000);
  }

  function warning_message(msg) {
    var x = document.getElementById("snackbar-warning");
    $("#snackbar-warning").text(msg);
    x.className = "show";
    setTimeout(function() {
      x.className = x.className.replace("show", "");
    }, 3000);
  }

  if ($('#msg-akzept').val() == 0) {
    $("#data-message-btn").attr("disabled", "disabled");
  }

  $('#msg-akzept').change(function() {
    if ($(this).is(":checked")) {
      $('#msg-akzept').val(1);
      $("#data-message-btn").removeAttr("disabled");
      return;
    }
    $('#msg-akzept').val(0);
    $("#data-message-btn").attr("disabled", "disabled");
  });

  $(document).on('submit', '#send-message-user-form', function() {
    var form_data = JSON.stringify($(this).serializeObject());
    $.ajax({
      url: 'admin/bin/ajaxHandlePayload.php',
      type: "POST",
      contentType: 'application/json',
      data: form_data,
      success: function(result) {
        if (result.status) {
          success_message(result.msg);
          $("#send-message-user-form").trigger("reset");
          $("#collapseNewGB").collapse('toggle');
        } else {
          warning_message(result.msg);
        }
      },
      error: function(xhr, resp, text) {
        // show error to console
        console.log(xhr, resp, text);
      }
    });

    return false;
  });
  //FORMULAR one
  if ($('#form-one-akzept').val() == 0) {
    $("#data_send_form_one").attr("disabled", "disabled");
  }

  $('#form-one-akzept').change(function() {
    if ($(this).is(":checked")) {
      $('#form-one-akzept').val(1);
      $("#data_send_form_one").removeAttr("disabled");
      return;
    }
    $('#form-one-akzept').val(0);
    $("#data_send_form_one").attr("disabled", "disabled");
  });

  $(document).on('submit', '#send-message-one', function() {
    var form_data = JSON.stringify($(this).serializeObject());
    $.ajax({
      url: 'admin/bin/ajaxHandlePayload.php',
      type: "POST",
      contentType: 'application/json',
      data: form_data,
      success: function(result) {
        if (result.status) {
          success_message(result.msg);
          $("#send-message-one").trigger("reset");
        } else {
          warning_message(result.msg);
        }
      },
      error: function(xhr, resp, text) {
        // show error to console
        console.log(xhr, resp, text);
      }
    });

    return false;
  });

  //FORMULAR two
  if ($('#form-two-akzept').val() == 0) {
    $("#data_send_form_two").attr("disabled", "disabled");
  }

  $('#form-two-akzept').change(function() {
    if ($(this).is(":checked")) {
      $('#form-two-akzept').val(1);
      $("#data_send_form_two").removeAttr("disabled");
      return;
    }
    $('#form-two-akzept').val(0);
    $("#data_send_form_two").attr("disabled", "disabled");
  });

  $(document).on('submit', '#send-message-two', function() {
    var form_data = JSON.stringify($(this).serializeObject());
    $.ajax({
      url: 'admin/bin/ajaxHandlePayload.php',
      type: "POST",
      contentType: 'application/json',
      data: form_data,
      success: function(result) {
        if (result.status) {
          success_message(result.msg);
          $("#send-message-two").trigger("reset");
        } else {
          warning_message(result.msg);
        }
      },
      error: function(xhr, resp, text) {
        // show error to console
        console.log(xhr, resp, text);
      }
    });
    return false;
  });

  $.fn.serializeObject = function() {
    var o = {};
    var a = this.serializeArray();
    $.each(a, function() {
      if (o[this.name] !== undefined) {
        if (!o[this.name].push) {
          o[this.name] = [o[this.name]];
        }
        o[this.name].push(this.value || '');
      } else {
        o[this.name] = this.value || '';
      }
    });
    return o;
  };

  $(document).on('click', '#data-email-btn', function() {
    delete_message_email();
  });

  function delete_message_email() {
    $.ajax({
      type: "POST",
      url: 'admin/bin/ajaxHandle.php',
      data: {
        'class': 'ContentAjax',
        'method': "delete_message_email",
        'id': $("#delete-id").val(),
        'email': $("#delete_msg_email").val()
      },
      success: OnDelete_message_email
    });
  }

  function OnDelete_message_email(content) {
    var response = $.parseJSON(content);
    if (response.status) {
      $("#content-delete-message").empty();
      $("#content-delete-message").html(response.template);
    } else {
      error_message(response.msg);

    }
  }

  $(document).on('click', '#delete_message_by_id', function() {
    var id = $(this).attr('data-id');
    delete_message_by_id(id);
  });

  function delete_message_by_id(id) {
    $.ajax({
      type: "POST",
      url: 'admin/bin/ajaxHandle.php',
      data: {
        'class': 'ContentAjax',
        'method': "delete_message_by_id",
        'id': id
      },
      success: OnDelete_message_by_id
    });
  }

  function OnDelete_message_by_id(content) {
    var response = $.parseJSON(content);
    if (response.status) {
      $("#content-delete-message").empty();
      $("#content-delete-message").html(response.template);
    } else {
      error_message(response.msg);
    }
  }

}); //document
